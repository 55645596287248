<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="d-none d-lg-flex align-items-end pl-0">
        <div class="w-100 d-lg-flex align-items-end justify-content-start">
          <b-img fluid :src="imgUrl" alt="Login V2" class="login-img" />
        </div>
      </b-col>
      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <div
          class="language-picker d-flex justify-content-between align-items-center"
        >
          <feather-icon icon="GlobeIcon" size="20" />
          <b-form-select
            @change="setSelectedLang()"
            class="form-control"
            v-model="selectedLang"
            :disabled="isLoggingIn"
          >
            <b-form-select-option value="null" selected>
              {{ T("Web.LoginPage.SelectLang", "Select language") }}
            </b-form-select-option>
            <b-form-select-option value="en">
              {{ T("Web.Generic.En", "English") }}
            </b-form-select-option>
            <b-form-select-option value="DK">{{
              T("Web.Generic.Dk", "Dansk")
            }}</b-form-select-option>
            <b-form-select-option value="NO">{{
              T("Web.Generic.No", "Norsk")
            }}</b-form-select-option>
            <b-form-select-option value="SE">
              {{ T("Web.Generic.Se", "Svensk") }}
            </b-form-select-option>
          </b-form-select>
        </div>

        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{
              showForgotPassword
                ? T("Web.LoginPage.ForgotPasswordTitle", "Forgot password")
                : T("Web.LoginPage.LoginTitle", "Login to continue")
            }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{
              showForgotPassword
                ? T(
                    "Web.LoginPage.ForgotPasswordText",
                    "We will send you a mail with a link to reset your password"
                  )
                : ""
            }}</b-card-text
          >

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label-for="login-email">
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{
                    T("Web.Generic.Email", "Email")
                  }}</label>
                  <b-link
                    v-if="showForgotPassword"
                    @click="toggleForgotPasswordSucess(false)"
                  >
                    <small>{{ T("Web.Generic.Back", "Back") }}</small>
                  </b-link>
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    :disabled="isLoggingIn"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                v-if="!showPasswordField"
                type="submit"
                variant="primary"
                class="mb-1"
                block
                @click="fetchLoginProvider"
                :disabled="fetchingLoginProvider"
              >
                {{T('Web.Generic.Continue')}}
              </b-button>
              <span v-else-if="!showForgotPassword">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{
                      T("Web.Generic.Password", "Password")
                    }}</label>
                    <b-link @click="toggleForgotPasswordSucess(true)">
                      <small>{{
                        T("Web.LoginPage.ForgotPassword", "Forgot password?")
                      }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        ref="loginPasswordInput"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                        :disabled="isLoggingIn"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-alert
                  :show="hasLoginError"
                  variant="danger"
                  class="p-1 d-flex align-items-center"
                  dismissible
                >
                  <feather-icon icon="AlertTriangleIcon" class="mr-1" />
                  {{ loginError }}</b-alert
                >

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="handleSubmit"
                  :disabled="isLoggingIn"
                >
                  <b-spinner small v-if="isLoggingIn" />
                  <span v-else>{{
                    T("Web.LoginPage.LoginBtn", "Log in")
                  }}</span>
                </b-button>
              </span>
              <span v-else>
                <b-button
                  type="submit"
                  variant="primary"
                  class="mb-1"
                  block
                  @click="sendForgotPassword"
                  :disabled="sendingForgotPassword"
                >
                  {{
                    sendingForgotPassword
                      ? T(
                          "Web.LoginPage.ForgotPasswordSending",
                          "Sending recovery mail"
                        )
                      : T("Web.LoginPage.ForgotPasswordBtn", "Reset")
                  }}
                </b-button>
                <strong v-if="showForgotPasswordSucess">{{
                  T(
                    "Web.LoginPage.ForgotPasswordSucess",
                    "A mail has been sent"
                  )
                }}</strong>
              </span>
            </b-form>
          </validation-observer>
          <!--
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group label-for="login-email">
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{
                    T("Web.Generic.Email", "Email")
                  }}</label>
                  <b-link
                    v-if="showForgotPassword"
                    @click="toggleForgotPasswordSucess(false)"
                  >
                    <small>{{ T("Web.Generic.Back", "Back") }}</small>
                  </b-link>
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    :disabled="isLoggingIn"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <span v-if="!showForgotPassword">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{
                      T("Web.Generic.Password", "Password")
                    }}</label>
                    <b-link @click="toggleForgotPasswordSucess(true)">
                      <small>{{
                        T("Web.LoginPage.ForgotPassword", "Forgot password?")
                      }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                        :disabled="isLoggingIn"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <label>{{
                  T("Web.LoginPage.SelectLang", "Select language")
                }}</label>
                <select
                  @change="setSelectedLang()"
                  class="form-control mb-1"
                  v-model="selectedLang"
                  :disabled="isLoggingIn"
                >
                  <option value="null" selected>
                    {{ T("Web.LoginPage.SelectLang", "Select language") }}
                  </option>
                  <option value="en">
                    {{ T("Web.Generic.En", "English") }}
                  </option>
                  <option value="DK">{{ T("Web.Generic.Dk", "Dansk") }}</option>
                  <option value="NO">{{ T("Web.Generic.No", "Norsk") }}</option>
                  <option value="SE">
                    {{ T("Web.Generic.Se", "Svensk") }}
                  </option>
                </select>
                <b-alert
                  :show="hasLoginError"
                  variant="danger"
                  class="p-1 d-flex align-items-center"
                  dismissible
                >
                  <feather-icon icon="AlertTriangleIcon" class="mr-1" />
                  {{ loginError }}</b-alert
                >

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="handleSubmit"
                  :disabled="isLoggingIn"
                >
                  <b-spinner small v-if="isLoggingIn" />
                  <span v-else>{{
                    T("Web.LoginPage.LoginBtn", "Log in")
                  }}</span>
                </b-button>
              </span>
              <span v-else>
                <b-button
                  type="submit"
                  variant="primary"
                  class="mb-1"
                  block
                  @click="sendForgotPassword"
                  :disabled="sendingForgotPassword"
                >
                  {{
                    sendingForgotPassword
                      ? T(
                          "Web.LoginPage.ForgotPasswordSending",
                          "Sending recovery mail"
                        )
                      : T("Web.LoginPage.ForgotPasswordBtn", "Reset")
                  }}
                </b-button>
                <strong v-if="showForgotPasswordSucess">{{
                  T(
                    "Web.LoginPage.ForgotPasswordSucess",
                    "A mail has been sent"
                  )
                }}</strong>
              </span>
            </b-form>
          </validation-observer>
          -->
          <p class="pt-2 text-center">
            <small
              >{{
                T(
                  "Web.LoginPage.GetAccess",
                  "Don't have access yet? Contact Affald.online to get it"
                )
              }}
              <a href="mailto:info@rezycl.com">{{
                T("Web.Generic.Here", "Here")
              }}</a></small
            >
          </p>
        </b-col>
      </b-col>
    </b-row>
    <notifications group="errors" position="bottom right" :duration="5000" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
  BAlert,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { mapActions, mapGetters } from "vuex";
import ResourceService from "@/services/base/resource.service";
import authHelper from "@/auth";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
    BFormSelect,
    BFormSelectOption,
  },
  mixins: [togglePasswordVisibility],
  created() {
    let rzToken = this.$route.query.rz_token;
    if (rzToken) {
      authHelper.setToken(rzToken);
      window.location.replace("/");
    }

    this.selectedLang = this.$cookies.get("SelectedLang");
    if (this.$route.query["forgot-password"]) {
      this.showForgotPassword = true;
    }
  },
  data() {
    return {
      showForgotPassword: false,
      sendingForgotPassword: false,
      showForgotPasswordSucess: false,
      selectedLang: undefined,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
      loggingIn: false,
      fetchingLoginProvider: false,
      showPasswordField: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggingIn: "appUser/isLogginIn",
      hasLoginError: "appUser/hasLoginError",
      loginError: "appUser/getLoginError",
    }),

    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      return require("@/assets/images/login-laptop-phone.png");
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions({
      tryLogin: "appUser/loginAsync",

      login: "auth/login",
      forgotPassword: "accountUsers/forgotPassword",
    }),
    async handleSubmit() {
      if (!(await this.$refs.loginValidation.validate())) return;
      await this.tryLogin({ email: this.userEmail, password: this.password });

      /*
      this.loggingIn = true;
      await this.login({ email: this.userEmail, password: this.password });
      this.setSelectedLang();
      this.loggingIn = false;
      */
    },
    toggleForgotPasswordSucess(value) {
      this.showForgotPassword = value;
      this.sendingForgotPassword = false;
      this.showForgotPasswordSucess = false;
    },
    async sendForgotPassword() {
      this.sendingForgotPassword = true;
      await this.forgotPassword({ email: this.userEmail });
      this.sendingForgotPassword = false;
      this.showForgotPasswordSucess = true;
    },
    setSelectedLang() {
      this.$cookies.set("SelectedLang", this.selectedLang);
      this.$i18n.locale = this.selectedLang;
    },
    async fetchLoginProvider() {
      if (!(await this.$refs.loginValidation.validate())) return;

      try {
        this.showPasswordField = false;
        this.fetchingLoginProvider = true;
        const service = new ResourceService("Auth/SSO/login-provider");
        let response = await service.post("", { email: this.userEmail });
        if (response.data.RedirectUrl == "false") {
          this.showPasswordField = true;
          this.$nextTick(() => {
            this.$refs["loginPasswordInput"].focus();
          });
        } else {
          window.location.href = response.data.RedirectUrl;
        }
      } finally {
        this.fetchingLoginProvider = false;
      }
    },
  },
  watch: {
    userEmail() {
      console.log(this.showForgotPassword);
      if (this.showPasswordField) {
        this.showPasswordField = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.language-picker {
  position: absolute;
  top: 10px;
  right: 0px;
  width: 204px;
  margin-right: 20px;
  gap: 10px;
}
.logo {
  width: 150px;
}

.auth-inner {
  background: url("~@/assets/images/login-bg.jpg");
  background-size: cover;
}
@media screen and (min-width: 1500px) {
  .login-img {
    max-width: 85%;
  }
}
</style>
